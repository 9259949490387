import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { MessageAlertComponent } from './messaging/message-alert/message-alert.component';
import { MessageMarkdownComponent } from './messaging/message-markdown/message-markdown.component';
import { BaseUrlInterceptor } from './interceptors/base-url-interceptor';
import { AppSettings } from './models/app-settings';
import { AppSettingsService } from './services/app-settings.service';
import { HelpDeskModalComponent } from './components/help-desk-modal/help-desk-modal.component';

export function initAppSettings(appSettings: AppSettingsService): () => Observable<AppSettings> {
  return () => appSettings.loadAppSettings();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NavbarComponent,
    WelcomeComponent,
    LogoutComponent,
    HelpDeskModalComponent,
    MessageAlertComponent,
    MessageMarkdownComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AlertModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [
    { provide: 'BASE_API_URL', useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initAppSettings, deps: [AppSettingsService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
