import { AppSettings } from './../models/app-settings';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, identity, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private appSettingsClient: HttpClient;

  appSettingsSubject = new BehaviorSubject<AppSettings>(undefined);

  constructor(private backendHandler: HttpBackend) {
    this.appSettingsClient = new HttpClient(backendHandler);
  }

  /**
   * Gets the most recently retrieved app settings values. This function or
   * {@linkcode getAppSettingsObservable} are the functions that most code
   * should use to inspect the app settings.
   *
   * Retrieving the app settings is done automatically by the app at the time
   * the app is initialized, so there is likely no need to call {@linkcode
   * loadAppSettings} yourself.
   */

  getAppSettings() {
    return this.appSettingsSubject.value;
  }

  /**
   * Gets an {@linkcode Observable<AppSettings>} that will emit the most
   * recently retrieved app settings values. This function or {@linkcode
   * getAppSettings} are the functions that most code should use to inspect the
   * app settings.
   *
   * Use this function if you intend to use an {@linkcode Observable} in an HTML
   * template to inspect the app settings and if you want to monitor for any
   * possible changes to the settings.
   *
   * Retrieving the app settings is done automatically by the app at the time
   * the app is initialized, so there is likely no need to call {@linkcode
   * loadAppSettings} yourself.
   *
   * @returns An {@linkcode Observable<AppSettings>} that emits when the app
   * settings have been loaded. Note that this Observable can emit multiple
   * times, so you must make sure to manage subscriptions to it accordingly.
   */

  getAppSettingsObservable(): Observable<AppSettings> {
    // This identity mapping is just to avoid exposing the private
    // BehaviorSubject to callers.
    return this.appSettingsSubject.pipe(identity);
  }

  /**
   * This function is generally NOT meant for code that just needs to see what
   * the app settings are.
   *
   * It loads the app settings from the server, which is done automatically at
   * app initialization time. That code is the only code that really needs to
   * call this.
   *
   * Code that just needs to know what the app settings values are should inject
   * AppSettingsService and call either {@linkcode getAppSettings} or
   * {@linkcode getAppSettingsObservable}.
   *
   * @returns An {@linkcode Observable<AppSettings>} that will provide the
   * current value of the app settings.
   */

  public loadAppSettings(): Observable<AppSettings> {
    if (this.appSettingsSubject.value !== undefined) {
      // If you see the following warning, it means you may be using this
      // service incorrectly. The app settings are loaded automatically at app
      // initialization time, so calling loadAppSettings in components or other
      // services should not be necessary. Following this initial load, you can
      // simple call getAppSettings. You can also call getAppSettingsObservable
      // at any time to get an Observable that is guaranteed to emit the latest
      // app settings value and update if for some reason that value changes.
      console.warn(
        'appsettingsLoaded was true in loadAppSettings. Unnecessary second call?');
    }

    const requestUrl = environment.hasOwnProperty('minig')
      ? `${window.location.origin}/madss-app/ext/assets/config/appsettings.json`
      : `${window.location.origin}/ext/assets/config/appsettings.json`;
    

    return this.appSettingsClient
      .get<AppSettings>(requestUrl)
      .pipe(
        tap(appSettings => {
          this.appSettingsSubject.next(appSettings);
        }),
        catchError(err => {
          console.error(err);

          this.appSettingsSubject.next({
            disableBasicLogin: true
          });

          return of(this.appSettingsSubject.value)
        })
      );
  }
}
