import {Component, Input, ViewChild} from '@angular/core';
import {AlertMessage} from '../../shared/models/alert-message';
import {AlertComponent} from 'ngx-bootstrap/alert';

/**
 * Component used to display alerts for the messaging API. These alerts appear
 * prominently near the top of the application.
 */

@Component({
  selector: 'app-message-alert',
  templateUrl: './message-alert.component.html',
  styleUrls: ['./message-alert.component.css']
})
export class MessageAlertComponent  {
  @Input()
  message: AlertMessage;

  @ViewChild(AlertComponent) alertComponent: AlertComponent;

  /**
   * Constructor.
   */

  constructor() {}

  /**
   * Called when this alert is dismissed. Removes the alert from the store.
   */

  onDismissAlert(): void {
    
  }

  /**
   * Handles clicks of the message buttons added by the creator of this alert.
   * Also triggers {@linkcode onDismissAlert}.
   *
   * @param action The function that should be called to handle the button
   * click. This should be the function that the creator of this alert
   * specified as the button action.
   */

  invokeButtonAction(action: () => void): void {
    this.onDismissAlert();
    action();
  }
}
