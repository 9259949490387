import { AppSettingsService } from '../services/app-settings.service';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

@Injectable()
export class MaintenanceRedirect  {

  appSettings = this.appSettingsService.getAppSettings();

  enableMaintenanceMode = this.appSettings.enableMaintenanceMode;

  readonly redirect = 'ext/welcome';


  constructor(private appSettingsService: AppSettingsService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean|UrlTree {
    return this.enableMaintenanceMode ?
      this.router.parseUrl('welcome') : true;
  }
}
